import { Link } from 'gatsby'
import React from 'react'
import FaqContent from '../components/faqContent'
import QuestionsSVG from '../components/illustrations/questions.svg'
import Layout from '../components/layout'
import Seo from '../components/seo'
import BackArrow from '../icons/back-arrow.svg'

const faq = () => {
  return (
    <Layout>
      <Seo title="FAQ" />
      <section id="faq" className="pt-8 lg:pt-16">
        <div className="layout-space">
          <div className="relative flex items-start mb-8 lg:mb-16 md:items-center md:justify-center">
            <Link to="/" className="h-auto md:absolute md:left-0">
              <img
                src={BackArrow}
                alt="Back to home page button"
                className="my-2 mr-6"
              />
            </Link>
            <h1 className="heading">Perguntas Frequentes</h1>
          </div>
          <div className="flex">
            <div className="mb-20 md:mb-32 md:w-1/2">
              <FaqContent />
            </div>
            <div className="hidden md:w-1/2 md:block md:pt-10 md:pl-8">
              <img
                src={QuestionsSVG}
                alt="Any questions?"
                className="questions-svg"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default faq
